import { useApolloClient, useLazyQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { startOfDay } from 'date-fns';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Controller, UseFieldArrayReturn, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import UserContext, { UserContextInterface } from '../../contexts/UserContext';
import {
	M_ProductDisplayForVisitsFragmentDoc,
	M_ProductForVisitsDocument,
	M_ProductSearchForVisitsDocument,
} from '../../graphql/__generated__/graphql';
import { ProductTypeUU, roleUuid } from '../../models';
import { getActiveServicesAndProductsWithOrWithoutInventory } from '../../utils/FilterUtil';
import { uiText } from '../../utils/Language';
import EntityLookup from '../entity-lookup/EntityLookup';
import FormatNumberInput from '../format-number-input/FormatNumberInput';
import { FyiModalComponent } from '../Modal/FyiModal';
import { ProductLineItemTableFormValues } from './ProductLineItemTable';
import { VisitFormValues } from './VisitForm';

type ProductLineItemTableRowProps = {
	field?: ProductLineItemTableFormValues['C_Orders'][0]['C_OrderLines'][0];
	index?: number;
	remove?: UseFieldArrayReturn['remove'];
	shouldDisplayTheDeleteColumn: boolean;
	isAddRow?: boolean;
	isDataReadOnly?: boolean;
	locator?: NonNullable<UserContextInterface['warehouse']['M_Locators']>[0];
};

const ProductLineItemTableRow = ({
	field,
	index,
	remove,
	shouldDisplayTheDeleteColumn,
	isAddRow = false,
	isDataReadOnly,
	locator,
}: ProductLineItemTableRowProps) => {
	const { t } = useTranslation();
	const graphqlClient = useApolloClient();
	const { register, getValues, setValue } = useFormContext<VisitFormValues>();
	const [showQuantityAlert, setShowQuantityAlert] = useState(false);
	const { role, user } = useContext(UserContext);

	const fieldPrefix = isAddRow ? 'addNewOrderLine' : `C_Orders.0.C_OrderLines.${index}`;

	const quantity = useWatch<ProductLineItemTableFormValues, 'C_Orders.0.C_OrderLines.0.QtyEntered'>({
		name: `${fieldPrefix}.QtyEntered` as 'C_Orders.0.C_OrderLines.0.QtyEntered',
	});
	const price = useWatch<ProductLineItemTableFormValues, 'C_Orders.0.C_OrderLines.0.PriceEntered'>({
		name: `${fieldPrefix}.PriceEntered` as 'C_Orders.0.C_OrderLines.0.PriceEntered',
	});
	const productUuid: string = useWatch<ProductLineItemTableFormValues, 'C_Orders.0.C_OrderLines.0.M_Product.UU'>({
		name: `${fieldPrefix}.M_Product.UU` as 'C_Orders.0.C_OrderLines.0.M_Product.UU',
	});
	const [getProduct, { data: product }] = useLazyQuery(M_ProductForVisitsDocument);
	useEffect(() => {
		if (productUuid && !isAddRow) {
			getProduct({ variables: { UU: productUuid }, fetchPolicy: isDataReadOnly ? 'cache-only' : 'network-only' });
		}
	}, [getProduct, productUuid, isAddRow, isDataReadOnly]);
	const initialProduct = graphqlClient.readFragment({
		id: field?.M_Product.UU,
		fragment: M_ProductDisplayForVisitsFragmentDoc,
	});
	const hasUserUpdatedThePrice = useRef(initialProduct ? price !== initialProduct.BH_SellPrice : false);

	// If this is a service, the add row, or the product doesn't have any storage on hand (such as when loading an existing visit),
	// we'll just say the totalQuantity is undefined
	const totalQuantity =
		product?.M_Product?.ProductType.UU === ProductTypeUU.Service ||
		isAddRow ||
		!product?.M_Product?.M_StorageOnHandList?.length
			? undefined
			: product.M_Product.M_StorageOnHandList.filter(
					(storage) =>
						storage.M_Locator.UU === locator?.UU &&
						(!storage.M_AttributeSetInstance.GuaranteeDate ||
							storage.M_AttributeSetInstance.GuaranteeDate >= startOfDay(new Date()).getTime()),
				).reduce((sum, storageOnHand) => sum + storageOnHand.QtyOnHand, 0);

	const [onSearchProductsAndServices, { data: productOptions, loading: areLoadingProducts }] = useLazyQuery(
		M_ProductSearchForVisitsDocument,
		{ fetchPolicy: 'cache-first' },
	);

	const isOrderLineNew = field?.isOrderLineNew;
	const reactivatedRow =
		useWatch<VisitFormValues, 'isVisitReactivated'>({ name: 'isVisitReactivated' }) && !isOrderLineNew && !isAddRow;

	const validateEnteredQuantity = useCallback(() => {
		if (
			(totalQuantity !== undefined &&
				product?.M_Product?.ProductType.UU !== ProductTypeUU.Service &&
				(getValues(`${fieldPrefix}.QtyEntered` as 'C_Orders.0.C_OrderLines.0.QtyEntered') || 0) > totalQuantity) ||
			(product &&
				!product?.M_Product?.M_StorageOnHandList?.length &&
				product?.M_Product?.ProductType.UU !== ProductTypeUU.Service)
		) {
			setShowQuantityAlert(true);
			setValue(`${fieldPrefix}.sellingTooMuch` as 'C_Orders.0.C_OrderLines.0.sellingTooMuch', '1');
		} else {
			setValue(`${fieldPrefix}.sellingTooMuch` as 'C_Orders.0.C_OrderLines.0.sellingTooMuch', '0');
		}
	}, [fieldPrefix, getValues, product, setValue, totalQuantity]);

	// If the product changes (and this is a new field where the user hasn't changed the price), update the price
	useEffect(() => {
		if (!hasUserUpdatedThePrice.current && product?.M_Product?.BH_SellPrice) {
			setValue(
				`${fieldPrefix}.PriceEntered` as 'C_Orders.0.C_OrderLines.0.PriceEntered',
				product.M_Product.BH_SellPrice,
			);
		}

		validateEnteredQuantity();
	}, [product, setValue, fieldPrefix, validateEnteredQuantity]);

	const isSalePriceReadOnly = useMemo(() => {
		const roleUuidsThatCannotEditSalePrice: string[] = [
			roleUuid.CASHIER_REGISTRATION_BASIC,
			roleUuid.CLINICIAN_NURSE_BASIC,
			roleUuid.INVENTORY_PHARMACY_BASIC,
			roleUuid.LAB_RADIOLOGY,
			roleUuid.MUST_HAVES,
		];
		// Filter our the roles that can't edit and, if there's nothing left, they can't edit it
		return (
			!user.IsAdministrator &&
			(role.AD_Role_IncludedList?.filter(
				(includedRole) => !roleUuidsThatCannotEditSalePrice.includes(includedRole.Included_Role.UU),
			).length || 0) === 0
		);
	}, [role, user]);

	return (
		<tr>
			<td>
				{!isAddRow && (
					<FyiModalComponent onHide={() => setShowQuantityAlert(false)} show={showQuantityAlert}>
						{t(uiText.visit.prompt.PRODUCT_LINE_QUANTITY_EXCEEDS_INVENTORY)}
					</FyiModalComponent>
				)}
				{!isAddRow && <input type="hidden" {...register(`${fieldPrefix}.UU` as 'C_Orders.0.C_OrderLines.0.UU')} />}
				<EntityLookup<ProductLineItemTableFormValues, 'C_Orders.0.C_OrderLines.0.M_Product'>
					name={`${fieldPrefix}.M_Product` as 'C_Orders.0.C_OrderLines.0.M_Product'}
					rules={{ required: !isAddRow }}
					isLoading={areLoadingProducts}
					id={`${fieldPrefix}.product`}
					delay={500}
					inputProps={{ 'aria-label': t(uiText.visit.form.product.table.PRODUCT_OR_SERVICE) }}
					emptyLabel={t(uiText.visit.product.NOT_FOUND)}
					labelKey={(data) =>
						graphqlClient.readFragment({ id: data.UU, fragment: M_ProductDisplayForVisitsFragmentDoc })?.Name || ''
					}
					minLength={2}
					placeholder={t(uiText.visit.product.SEARCH)}
					promptText={t(uiText.visit.product.SEARCHING)}
					searchText={t(uiText.visit.product.SEARCHING)}
					options={productOptions?.M_ProductGet.Results || []}
					onSearch={(query) =>
						onSearchProductsAndServices({
							variables: { Filter: getActiveServicesAndProductsWithOrWithoutInventory(query, locator?.UU).toString() },
						})
					}
					disabled={isDataReadOnly || reactivatedRow}
					onChange={() => {
						// We need to know, ultimately, whether the price can be updated when the product changes
						// So, if the user has changed the price, it's value will differ from the product's
						// In that case, don't update anything
						// This field is typically set when the user is editing a visit, but this is for when
						// a visit is being viewed and the "product gets selected")
						hasUserUpdatedThePrice.current = price !== product?.M_Product?.BH_SellPrice;
					}}
				/>
			</td>
			<td>
				<Form.Control
					aria-label={t(uiText.visit.form.product.table.INSTRUCTIONS)}
					{...register(`${fieldPrefix}.Description` as 'C_Orders.0.C_OrderLines.0.Description')}
				/>
			</td>
			<td>
				{isDataReadOnly || product?.M_Product?.ProductType.UU === ProductTypeUU.Service ? (
					<Form.Control
						aria-label={t(uiText.visit.form.product.table.EXISTING_QUANTITY)}
						readOnly
						className="text-end"
						type="text"
						value={'-'}
					/>
				) : (
					<FormatNumberInput
						aria-label={t(uiText.visit.form.product.table.EXISTING_QUANTITY)}
						value={totalQuantity}
						tabIndex={-1}
						readOnly={true}
						displayAndUseZeroIfEmpty={!isAddRow}
					/>
				)}
			</td>
			<td>
				<input
					type="hidden"
					{...register(`${fieldPrefix}.sellingTooMuch` as 'C_Orders.0.C_OrderLines.0.sellingTooMuch', {
						validate: (value) => isAddRow || value === '0',
					})}
				/>
				<Controller<ProductLineItemTableFormValues, 'C_Orders.0.C_OrderLines.0.QtyEntered'>
					name={`${fieldPrefix}.QtyEntered` as 'C_Orders.0.C_OrderLines.0.QtyEntered'}
					rules={{
						required: !isAddRow,
						validate: (value) => isAddRow || value !== 0,
					}}
					render={({ field }) => (
						<FormatNumberInput
							aria-label={t(uiText.visit.form.product.table.QUANTITY)}
							displayAndUseZeroIfEmpty={!isAddRow}
							{...field}
							onBlur={() => {
								validateEnteredQuantity();
								field.onBlur();
							}}
							min={0}
						/>
					)}
				/>
			</td>
			<td>
				<Controller<ProductLineItemTableFormValues, 'C_Orders.0.C_OrderLines.0.PriceEntered'>
					name={`${fieldPrefix}.PriceEntered` as 'C_Orders.0.C_OrderLines.0.PriceEntered'}
					render={({ field }) => (
						<FormatNumberInput
							aria-label={t(uiText.visit.form.product.table.UNIT_SELL_PRICE)}
							displayAndUseZeroIfEmpty={!isAddRow}
							{...field}
							onChange={(e) => {
								hasUserUpdatedThePrice.current = true;
								field.onChange(e);
							}}
							disabled={isSalePriceReadOnly}
						/>
					)}
				/>
			</td>
			<td>
				<FormatNumberInput
					aria-label={t(uiText.visit.form.product.table.TOTAL)}
					value={(quantity || 0) * (price || 0)}
					tabIndex={-1}
					readOnly={true}
				/>
			</td>
			{shouldDisplayTheDeleteColumn && !reactivatedRow && (
				<td className="print__d-none align-middle text-center">
					{!isAddRow && (
						<button
							type="button"
							aria-label={t(uiText.visit.form.product.DELETE)}
							className="btn p-0 w-100"
							tabIndex={-1}
							onClick={() => remove && remove(index)}
						>
							<FontAwesomeIcon icon="trash" />
						</button>
					)}
				</td>
			)}
		</tr>
	);
};

export default ProductLineItemTableRow;
